<template lang="pug">
.filters
  .row.align-items-center.m-b-10
    .col-md-12
      .d-flex.align-items-center
        AppAddNewButton.add-new-button.m-r-6(
          @click="openNewNotificationSetting"
        )
          span.m-l-10 {{ $t("actions.add_new") }}
</template>

<script>
  // mixins
  import withModal from "@/mixins/withModal"

  // components
  import NotificationForm from "./Form"

  export default {
    components: {
      AppAddNewButton: () => import("@/components/elements/AppButton/WithIcon/AddNew")
    },

    mixins: [withModal],

    methods: {
      openNewNotificationSetting() {
        this.$openModal({
          component: NotificationForm,
          title: this.$t("inventory_notifications.add_new_notification"),
          size: "large"
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .filters
    +filter-title

    margin-left: -6px

    .add-new-button
      margin-left: 6px
</style>
