export default () => ({
  SET_ITEM: (state, item) => {
    state.item = item
  },

  SET_CAR_CLASS_ITEM_BY_INDEX(state, { item, settingItemIndex, index }) {
    state.item.setting_items[settingItemIndex].car_classes.splice(index, 1, item)
  },

  SET_POPUP_LOADING: (state, value) => {
    state.popupLoading = value
  }
})
