<template lang="pug">
  .inventory-notifications-table
    VueGoodTable.table-wrapper(
      :rows="notifications"
      :columns="columns"
    )
      template(v-slot:table-column="props")
        span(:title="props.column.label") {{ $t(props.column.label) }}
      template(v-slot:table-row="{ column, row, formattedRow }")
        .table-actions(v-if="column.field === 'actions'")
          AppIconButton.action-button(
            :class="{ disabled: false }"
            icon="edit"
            @click="editNotification(row)"
          )
          AppIconButton.action-button(
            :class="{ disabled: false }"
            icon="trash-alt"
            @click="deleteNotification(row)"
          )
      template(v-slot:emptystate)
        .text-center {{ $t("no_data") }}
</template>

<script>
  import { VueGoodTable } from "vue-good-table"

  // misc
  import withModal from "@/mixins/withModal"
  import withConfirmation from "@/mixins/withConfirmation"
  import { columns } from "./TableConfig.js"
  import "vue-good-table/dist/vue-good-table.css"

  import NotificationForm from "../Form"

  export default {
    props: {
      notifications: {
        type: Array,
        default: () => new Array()
      },
      sortBy: {
        type: Object,
        default: () => {}
      }
    },

    mixins: [withModal, withConfirmation],

    components: {
      VueGoodTable,
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    computed: {
      columns
    },

    methods: {
      changePagination(key, value) {
        this.$emit("change-pagination", { key, value })
      },

      editNotification({ id }) {
        this.$openModal({
          component: NotificationForm,
          title: this.$t("inventory_notifications.edit_inventory_notication"),
          size: "large",
          props: {
            notificationId: id
          }
        })
      },

      deleteNotification(data) {
        this.$confirm({
          title: this.$t("inventory_notifications.delete_confirmation"),
          resolve: {
            handler: async () => {
              this.$emit("delete-notification", data.id)
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .inventory-notifications-table
    +listing-container(-48px)
  ::v-deep
    .table-actions
      text-align: center

      .app-icon
        fill: $default-purple
        height: 18px
        width: 18px

      .action-button
        +icon-button($default-purple)
</style>
